import {addPacketQueue} from "../wasm/aribWorker";

export async function processFile(tsFile,worker,progressCallback){
    let fr = new FileReader();
    let buffer_size=188*1024*20;
    function getFileWithRange(file,start,length){
        return new Promise(resolve=>{
            fr.onload = function() {
                let data = fr.result;
                resolve(new Uint8Array(data));
            };
            fr.readAsArrayBuffer(file.slice(start,start+length));
        });
    }
    let offset = 0;

    async function seekToStart(file){
        let buf = await getFileWithRange(file,0,188*2);
        for (let i = 0; i < 188; i++) {
            if (buf[i] == 0x47 && buf[i + 188] == 0x47) {
                offset=i;
                console.log("success");
                return true;
            }
        }
        return false;
    }

    async function seekToNext(file){
        let buf = await getFileWithRange(file,offset,188*2);
        for (let i = 0; i < 188; i++) {
            if (buf[i] == 0x47 && buf[i + 188] == 0x47) {
                offset=i+offset;
                console.log("success");
                return true;
            }
        }
        return false;
    }

    if(await seekToStart(tsFile)){
        let i=0;
        while (true){
            let buffer = await getFileWithRange(tsFile,offset,buffer_size);
            if(buffer.length===0){
                return true;
            }
            let result = JSON.parse(await worker.addPacketQueue(buffer));
            if(!result.success){
                offset = offset + result.data;
                if(await seekToNext(tsFile)){
                    continue;
                }
                return false;
            }
            i++;
            if(i % 10){
                progressCallback(offset/tsFile.size)
            }
            offset=offset+buffer_size;
        }
    }
}
