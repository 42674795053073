import ARIB from "./tsutil";
import aribWASM from "./tsutil.wasm";
import aribLib from "./tsutil.wasm";

let arib = null;
let batchFeedPack = null;
export async function init(){
    if(!arib){
        arib = await ARIB({
            locateFile(path) {
                if(path.endsWith('.wasm')) {
                    return aribLib;
                }
                return path;
            }
        })
        batchFeedPack=arib.cwrap("batch_feed_packet","string",["array","number"])
        arib._init();
    }
    return arib.cwrap("str_test","string")();
}

export async function addPacketQueue(packets){
    return batchFeedPack(packets,packets.length);
}

export async function getResult(){
    return arib.cwrap("get_result","string")();
}
