import './App.css';
import {useEffect, useRef, useState} from "react";
import {createWorkerFactory, useWorker} from '@shopify/react-web-worker';
import {processFile} from "./util/file_util";
import {Button, CustomProvider, Panel, Stack, Uploader, Progress} from 'rsuite';
// import default style
import 'rsuite/dist/rsuite.min.css';
import SubtitlePreview from "./components/SubtitlePreview/SubtitlePreview"; // or 'rsuite/dist/rsuite.min.css'
const createWorker = createWorkerFactory(() => import('./wasm/aribWorker'));

function App() {
    const [hasInit,setInit] = useState(false);
    const [isWorking, setWorking] = useState(false);
    const [result,setResult] = useState(null);
    const [tsFile,setTSFile] = useState(null);
    const worker = useWorker(createWorker);
    const [progress,setProgress] = useState(0);
    useEffect(() => {
        (async () => {
            if(!hasInit){
                const webWorkerMessage = await worker.init();
                console.log(webWorkerMessage);
                setInit(true);
            }
        })();
    }, [worker]);
    useEffect(()=>{
        console.log(result);
    },[result])
    return (
        <CustomProvider theme="dark">
            <div className="App">
                <div>
                    <div style={{fontSize:"34px"}}>ARIB字幕解析ツール</div>
                    <div>ブラウザだけでARIB字幕を解析しよ！</div>
                </div>
                <div className={"desc"}>
                    <p>
                        ARIB字幕は、電波産業会（ARIB）により、
                        デジタルテレビの字幕放送で使われている字幕ファイル交換フォーマットである。
                        ARIB STD-B36で規格化されている。
                        これは統合デジタル放送サービスの一部として、
                        20年以上に渡って日本全国に使われてるが、
                        いまだに多くのコーデックライブラリにサポートされていない。
                    </p>
                    <p>
                        当サイトはMPEG-TSファイルから、ARIB字幕をより簡単で解析できるため設立した。
                        全ての解析作業はサーバー側と通信をせずにブラウザで行い、ASSやSRT字幕ファイルを出力します。
                    </p>
                </div>
                <div>
                    {tsFile==null?<Uploader
                        autoUpload={false}
                        className={"file-select"}
                        fileListVisible={false}
                        multiple={false}
                        onChange={(fileList => {
                            if (fileList.length > 0) {
                                setTSFile(fileList[0]);
                            }
                        })}
                        draggable action={""}>
                        <div>
                            <div style={{lineHeight: "200px"}}>
                                ここにファイルをドラッグまたはクリックしデバイスのファイルを選択してください
                            </div>
                        </div>
                    </Uploader>:<>
                        <Panel header={tsFile.name} className={"file-select"} bordered>
                            {isWorking && result==null?<div style={{maxWidth:"200px",margin:"auto"}}>
                                <Progress.Circle percent={Math.ceil(progress*100)}
                                                 strokeColor={progress*100===100?"#52c41a":"#3385ff"}
                                                 status={progress*100===100?"success":null} />
                            </div>:result==null?<Stack spacing={30} alignItems={"center"} justifyContent={"center"}>
                                <Button onClick={()=>setTSFile(null)}>戻る</Button>
                                <Button color={"green"} appearance={"primary"} onClick={async () => {
                                    setWorking(true);
                                    await processFile(tsFile.blobFile, worker, setProgress);
                                    setProgress(1);
                                    setTimeout(()=>{
                                        (async ()=>{
                                            setResult(JSON.parse(await worker.getResult()));
                                            setWorking(false);
                                            setProgress(0);
                                        })();
                                    },500);
                                }}>決定</Button>
                            </Stack>:<SubtitlePreview data={result} reset={()=>{
                                setResult(null);
                                setTSFile(null);
                            }}/>}

                        </Panel>
                    </>}
                </div>
            </div>
        </CustomProvider>
    );
}

export default App;
