export function getHeader(width,height){
    return "[Script Info]\n" +
        `; Original ${width}x${height}(16:9)\n` +
        "Title: Default SSA V4+ (ASS)\n" +
        "ScriptType: v4.00+\n" +
        "WrapStyle: 0\n" +
        `PlayResX: ${width}\n` +
        `PlayResY: ${height}\n` +
        "ScaledBorderAndShadow: yes\n" +
        "\n" +
        "[V4+ Styles]\n" +
        "Format: Name, Fontname, Fontsize, PrimaryColor, SecondaryColor, OutlineColor, BackColor, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding\n" +
        "Style: Default,HG丸ｺﾞｼｯｸM-PRO,40,&H00FFFFFF,&H000000FF,&H00000000,&H00000000,0,0,0,0,100,100,0,0,1,2,2,1,10,10,10,0\n" +
        "Style: Box,HG丸ｺﾞｼｯｸM-PRO,40,&HFFFFFFFF,&H000000FF,&H00FFFFFF,&H00FFFFFF,0,0,0,0,100,100,0,0,1,2,2,2,10,10,10,0\n" +
        "Style: Rubi,HG丸ｺﾞｼｯｸM-PRO,18,&H00FFFFFF,&H000000FF,&H00000000,&H00000000,0,0,0,0,100,100,0,0,1,2,2,1,10,10,10,0\n" +
        "//\n" +
        "\n" +
        "[Events]\n" +
        "Format: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text\n";
}