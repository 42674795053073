import {Button, Radio, RadioGroup, Stack, Timeline} from "rsuite";
import { saveAs } from 'file-saver';

import {useEffect, useState} from "react";
import "./SubtitlePreview.css"
import {processFile} from "../../util/file_util";
import {getHeader} from "../../util/ass_util";
const styles = {
    radioGroupLabel: {
        padding: '8px 2px 8px 10px',
        display: 'inline-block',
        verticalAlign: 'middle'
    }
};
function paddingZero(number){
    if(number<10){
        return "0"+number;
    }
    return number;
}
function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(color) {
    return componentToHex((color & 0xff0000) >>16) + componentToHex((color & 0xff00) >>8) + componentToHex(color & 0xff);
}

function msToHMS(ms,srt){
    let seconds = ms / 1000;
    const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = parseFloat((seconds % 60).toFixed(2));
    if(srt){
        seconds = parseFloat((seconds % 60).toFixed(3));
    }
    return ( paddingZero(hours)+":"+paddingZero(minutes)+":"+paddingZero(seconds));
}

export default function SubtitlePreview(props){
    const [subFormat,setSubFormat] = useState("srt");
    return <><div className={"subtitle-list"}>
        <Timeline align={"left"}>
            {
                props.data.map((item)=><Timeline.Item>
                    <p>{msToHMS(item.from)} ~ {msToHMS(item.to)}</p>
                    {
                        item.captions.map(cap=>{
                            return <p style={{color:`rgb(${(cap.color & 0xFF0000)>>16},${(cap.color & 0xFF00)>>8},${cap.color & 0xFF})`}}>{cap.text}</p>
                        })
                    }
                </Timeline.Item>)
            }
        </Timeline>
    </div>
    <Stack spacing={10} style={{marginTop:20}} alignItems={"center"} justifyContent={"center"}>
        <RadioGroup name="radioList" inline appearance="picker" defaultValue="srt" value={subFormat} onChange={setSubFormat}>
            <span style={styles.radioGroupLabel}>字幕フォーマット: </span>
            <Radio value="srt">SRT</Radio>
            <Radio value="ass">ASS</Radio>
        </RadioGroup>
        <Button color={"green"} appearance={"primary"} onClick={() => {
            if(subFormat=="ass"){
                let screenW=0;
                let screenH=0;
                let subtitles = [];
                props.data.forEach(region=>{
                    let start = msToHMS(region.from);
                    let end = msToHMS(region.to);
                    screenW=region.plane_width;
                    screenH=region.plane_height;

                    region.captions.forEach(sub=>{
                        subtitles.push(`Dialogue: 0,${start},${end},Default,,0000,0000,0000,,{\\pos(${sub.x},${sub.y})\\c&H${rgbToHex(sub.color)}&}${sub.text} \\N`);
                    })
                    region.ruby?.forEach(sub=>{
                        subtitles.push(`Dialogue: 0,${start},${end},Rubi,,0000,0000,0000,,{\\pos(${sub.x},${sub.y})\\c&H${rgbToHex(sub.color)}&}${sub.text} \\N`);
                    })
                });
                let header = getHeader(screenW,screenH);
                console.log([header,...subtitles].join("\n"));
                saveAs(new Blob([[header,...subtitles].join("\n")], {type: "text/plain;charset=utf-8"}),"subtitle.ass");
            }else{
                let subtitles = [];
                props.data.forEach((region,i)=>{
                    let start = msToHMS(region.from,true).replace(/\./g,",");
                    let end = msToHMS(region.to,true).replace(/\./g,",");
                    let sub = `${i+1}\n${start} --> ${end}\n`;
                    region.captions.forEach(s=>{
                        sub+= `${s.text}\\N`;
                    })
                    sub+="\n";
                    subtitles.push(sub);
                });
                subtitles.join("\n");
                saveAs(new Blob([subtitles.join("\n")], {type: "text/plain;charset=utf-8"}),"subtitle.srt");
            }


        }}>保存する</Button>
        <Button color={"red"} appearance={"primary"} onClick={()=>{
            props.reset();
        }}>
            やり直す
        </Button>
    </Stack>
    </>
}